import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
// eslint-disable-next-line import/no-extraneous-dependencies
import { capitalize, clone, compact, isEmpty, map } from 'lodash-es';
import { SeoService } from './seo.service';
import { CommonService } from './common.service';
import { TitleCasePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  public showSearchSidebar;
  public showExtendedSearchSidebar = new BehaviorSubject<boolean>(false);
  public showLyrics = new BehaviorSubject<boolean>(false);
  public showSettings = new BehaviorSubject<boolean>(false);
  public showPlaylistMenu = new BehaviorSubject<boolean>(false);
  public hideSearchTab = new BehaviorSubject<boolean>(false);
  public searchProfile = new BehaviorSubject<any>({});
  public showFullScreenSearch;
  public lyric: any = null;
  public isSmallSize = window.innerWidth <= 1024;
  public windowHeight = window.innerHeight - 100;
  public goToEditorial = false;
  public editorialSearchParam: string = '';
  public searchTags = new Subject();
  public scrolled = new BehaviorSubject<boolean>(false);
  public searchChangeValue = new BehaviorSubject<string>('');
  public isYoutubeReference = false;
  public isProcessingReference = new BehaviorSubject<'none' | 'processing' | 'end'>('none');
  public percentageReference = new BehaviorSubject<string>('0');
  public externalReference = new BehaviorSubject<string>('');
  private SIMILAR_SONG_STORAGE = 'SOSTEREO.similarSongRef';
  public draggedSong: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowHeight = window.innerHeight - 100;
    this.isSmallSize = window.innerWidth <= 1024;
  }

  constructor(
    private seoService: SeoService,
    private commonService: CommonService,
    private titleCasePipe: TitleCasePipe,
  ) {}

  public getLyircsHiglights(song) {
    let lyric = song.lyric;
    if (song.hasOwnProperty('highlight') && !isEmpty(song.highlight)) {
      song.highlight['versions.lyric'].forEach((fragment) => {
        const replaceFragment = fragment
          .replace(new RegExp('</em>', 'g'), '')
          .replace(new RegExp('<em>', 'g'), '')
          .replace(/([.*+#?^=!:${}()|\[\]\/\\])/g, '\\$1');
        lyric = lyric.replace(
          new RegExp(replaceFragment, 'g'),
          fragment.replace(new RegExp('<em>', 'g'), '<em class="fw600">'),
        );
      });
    }
    return lyric;
  }

  mapArtists(artists, playlistData) {
    if (artists) {
      artists.forEach((artist) => {
        if (playlistData.artists[artist.artistId]) {
          artist.artistName = playlistData.artists[artist.artistId].name;
          artist.artistSlug = playlistData.artists[artist.artistId].slug;
          artist.artistImage = playlistData.artists[artist.artistId].profileImages?.thumbUrl;
        }
      });
    }
    return artists;
  }

  // This is to add the playlist song into the versions array so the logic don't change in the html
  addSelectedVersion(playlistData) {
    playlistData.items.forEach((song) => {
      const originalVersions = clone(song.versions);
      const songToAdd = clone(song);
      delete songToAdd.versions;
      song.versions = {
        instrumental: {},
        vocals: {},
        match: {},
        others: [],
      };

      let versionIdx = -1;
      // If version in PL es vocals=true
      if (song.hasOwnProperty('vocals') && song.vocals) {
        song.versions.vocals = songToAdd;
        song.versions.vocals.artists = this.mapArtists(song.versions.vocals.artists, playlistData);
        song.versions.match = song.versions.vocals;
        song.versions.vocals.name = song.versions.vocals.fileName;

        for (let index = 0; index < originalVersions.length; index++) {
          if (
            originalVersions[index].songVersion.toLowerCase() === 'original' &&
            originalVersions[index].hasOwnProperty('vocals') &&
            !originalVersions[index].vocals
          ) {
            song.versions.instrumental = originalVersions[index];
            if (song.versions.instrumental.fileName) {
              song.versions.instrumental.name = song.versions.instrumental.fileName;
            }
            versionIdx = index;
            break;
          }
          if (
            originalVersions[index].songVersion.toLowerCase() === 'instrumental' &&
            originalVersions[index].hasOwnProperty('vocals') &&
            !originalVersions[index].vocals
          ) {
            song.versions.instrumental = originalVersions[index];
            if (song.versions.instrumental.fileName) {
              song.versions.instrumental.name = song.versions.instrumental.fileName;
            }
            versionIdx = index;
            break;
          }
          if (originalVersions[index].hasOwnProperty('vocals') && !originalVersions[index].vocals) {
            song.versions.instrumental = originalVersions[index];
            if (song.versions.instrumental.fileName) {
              song.versions.instrumental.name = song.versions.instrumental.fileName;
            }
            versionIdx = index;
          }
        }
        // Remove inst version from originalVersions so it won't be in 'others' array
        if (versionIdx !== -1) {
          song.versions.instrumental.artists = this.mapArtists(
            song.versions.instrumental.artists,
            playlistData,
          );
          originalVersions.splice(versionIdx, 1);
        }
        song.versions.others = originalVersions;
        if (!isEmpty(song.versions.others)) {
          song.versions.others.forEach((version) => {
            if (version.fileName) {
              version.name = version.fileName;
            }
            version.artists = this.mapArtists(version.artists, playlistData);
          });
        }
      } else {
        // If version in PL es vocals=false
        song.versions.instrumental = songToAdd;
        song.versions.instrumental.artists = this.mapArtists(
          song.versions.instrumental.artists,
          playlistData,
        );
        song.versions.match = song.versions.instrumental;
        song.versions.instrumental.name = song.versions.instrumental.fileName;
        for (let index = 0; index < originalVersions.length; index++) {
          if (
            originalVersions[index].songVersion.toLowerCase() === 'original' &&
            originalVersions[index].hasOwnProperty('vocals') &&
            originalVersions[index].vocals
          ) {
            song.versions.vocals = originalVersions[index];
            if (song.versions.vocals.fileName) {
              song.versions.vocals.name = song.versions.vocals.fileName;
            }
            versionIdx = index;
            break;
          }
          if (originalVersions[index].hasOwnProperty('vocals') && originalVersions[index].vocals) {
            song.versions.vocals = originalVersions[index];
            if (song.versions.vocals.fileName) {
              song.versions.vocals.name = song.versions.vocals.fileName;
            }
            versionIdx = index;
          }
        }
        // Remove vocals version from originalVersions so it won't be in 'others' array
        if (versionIdx !== -1) {
          song.versions.vocals.artists = this.mapArtists(
            song.versions.vocals.artists,
            playlistData,
          );
          originalVersions.splice(versionIdx, 1);
        }
        song.versions.others = originalVersions;
        if (!isEmpty(song.versions.others)) {
          song.versions.others.forEach((version) => {
            if (version.fileName) {
              version.name = version.fileName;
            }
            version.artists = this.mapArtists(version.artists, playlistData);
          });
        }
      }
    });
  }

  updateSeo(data) {
    const seoData = {
      imageWidth: '1600',
      imageHeight: '800',
      pageBackground: data.pageBackground,
      pageTitle: data.pageTitle || data.pageTitle,
      pageDescription: data.pageDescription || data.description,
    };
    this.seoService.updateImageSeo(seoData);
    this.seoService.updateBasicSeo(seoData);
    this.seoService.updateWindowPrerender(true);
  }

  updatePlaylistSEO(res: any) {
    const seoData: any = {};
    let backgroundMeta = compact(map(res.data.artists, 'backgroundImages'));
    backgroundMeta = backgroundMeta.filter((array) => {
      return array.length > 0;
    });
    seoData.pageTitle = `Playlist - ${res.data.name}`;
    seoData.pageDescription =
      res.data.description && res.data.description !== ''
        ? res.data.description
        : this.commonService.pageDescription.getValue()
        ? this.commonService.pageDescription.getValue()
        : 'The easiest way for brands to innovate their music strategy & execution by streamlining discovery, understanding, and music licensing tools.';
    seoData.pageBackground =
      backgroundMeta.length > 0 ? backgroundMeta[0][0]?.replace('@xlarge', '@medium') : null;
    this.updateSeo(seoData);
  }

  artistSeoUpdate(data: any, urlPrefix: string, currentArtist: any) {
    if (data.items.length > 0) {
      const seoData = clone(currentArtist);
      seoData.pageBackground =
        seoData.backgroundImages?.length > 0
          ? seoData.backgroundImages[0].replace('@xlarge', '@social')
          : null;
      seoData.pageTitle =
        seoData.name?.replace(/[^a-zA-Z0-9 .,¿?¡!+-_=()&$*]/g, '') +
        ` - ${
          this.commonService.tenantId === 'sostereo'
            ? 'SoStereo'
            : capitalize(this.commonService.tenantId)
        }`;
      this.updateSeo(seoData);
    }
  }

  getLocationString(artistLocation) {
    if (
      artistLocation.hasOwnProperty('city') &&
      artistLocation.city &&
      artistLocation.city !== ''
    ) {
      return (
        artistLocation.city +
        (artistLocation.hasOwnProperty('state') &&
        artistLocation.state &&
        artistLocation.state !== ''
          ? ', ' + artistLocation.state
          : artistLocation.hasOwnProperty('country') &&
            artistLocation.country &&
            artistLocation.country !== ''
          ? ', ' + artistLocation.country
          : '')
      );
    } else if (
      artistLocation.hasOwnProperty('state') &&
      artistLocation.state &&
      artistLocation.state !== ''
    ) {
      return artistLocation.state;
    } else if (
      artistLocation.hasOwnProperty('country') &&
      artistLocation.country &&
      artistLocation.country !== ''
    ) {
      return artistLocation.country;
    } else {
      return '';
    }
  }

  getReferenceSeedSongId(queryParams: any, references: any[]) {
    const similarSongStorage = localStorage.getItem(this.SIMILAR_SONG_STORAGE);
    if (references?.length > 0) {
      // isEmpty(references[0]?.versions?.vocals) is when the song is original but instrumental
      references[0].versions.match =
        (queryParams.hasOwnProperty('vocals') && queryParams.vocals === 'false') ||
        isEmpty(references[0]?.versions?.vocals)
          ? references[0].versions.instrumental
          : references[0].versions.vocals;
      return references[0];
    } else {
      return similarSongStorage ? JSON.parse(similarSongStorage) : null;
    }
  }

  getSongReference(queryParams: any, references: any[]) {
    const similarSongStorage = localStorage.getItem(this.SIMILAR_SONG_STORAGE);
    if (queryParams.hasOwnProperty('seed_youtube') && similarSongStorage) {
      return JSON.parse(similarSongStorage);
    }
    if (queryParams.hasOwnProperty('seed_songIds') && queryParams.hasOwnProperty('fields')) {
      return this.getReferenceSeedSongId(queryParams, references);
    }
    localStorage.removeItem(this.SIMILAR_SONG_STORAGE);
    return null;
  }

  isSameSong(songReference: any, songToCompare: any) {
    const isOtherVersion = songToCompare.versions.others?.some(
      (version) => !!version._id && version._id === songReference.versions?.match._id,
    );
    const isVocal =
      !!songToCompare.versions.vocals._id &&
      songToCompare.versions.vocals._id === songReference.versions?.match?._id;
    const isInstrumental =
      !!songToCompare.versions.instrumental._id &&
      songToCompare.versions.instrumental._id === songReference.versions?.match._id;
    return isOtherVersion || isVocal || isInstrumental;
  }

  urlIncludesReference(songReference: any, queryParams: any): boolean {
    const idReference = queryParams.seed_youtube || queryParams.seed_songIds;
    if (idReference && songReference) {
      return this.isSameSong({ versions: { match: { _id: idReference } } }, songReference);
    }
    return false;
  }

  setAgeChart(ageGraphData) {
    const biggestAge = ageGraphData.reduce(
      (max, item) => (max.totalPercent > item.totalPercent ? max : item),
      ageGraphData[0],
    );
    let ageChartData;
    ageChartData = [Math.floor(biggestAge.totalPercent), 100 - Math.floor(biggestAge.totalPercent)];
    const ageChart = {
      chartData: {
        datasets: [
          {
            data: ageChartData,
            backgroundColor: ['#DEDEDE', '#868E8F'],
            borderWidth: 0,
            cutout: '90%',
          },
        ],
      },
      chartPercentage: Math.floor(biggestAge.totalPercent),
      chartTitle: biggestAge.age,
    };
    return ageChart;
  }

  setSocialChart(statsObject, socialMediaPriority) {
    const data = [];
    const labels = ['', '', ''];
    let socialChart = null;
    socialMediaPriority.forEach((sn) => {
      if (statsObject[sn] && data.length < 3) {
        let dataNumber = null;
        if (
          sn === 'spotify' &&
          statsObject[sn].monthlyListeners &&
          statsObject[sn].monthlyListeners > 10
        ) {
          dataNumber = statsObject[sn].monthlyListeners;
        } else if (statsObject[sn].followers && statsObject[sn].followers > 10) {
          dataNumber = statsObject[sn].followers;
        }
        if (dataNumber !== null) {
          data.push(dataNumber);
          labels[data.length - 1] = this.titleCasePipe.transform(sn);
        }
      }
    });
    if (data.length > 0) {
      socialChart = {
        chartData: [{ data: data, label: '' }],
        labels: labels,
      };
    }
    return socialChart;
  }

  setGenderChart(genderGraphData, isPitch?: boolean) {
    const biggestGender = genderGraphData.female > genderGraphData.male ? 'female' : 'male';
    let genderPercentage: number;
    let genderChartData;
    if (biggestGender === 'male') {
      genderPercentage = Math.floor(
        (genderGraphData.male * 100) / (genderGraphData.male + genderGraphData.female),
      );
      genderChartData = [genderGraphData.male, genderGraphData.female];
    } else {
      genderPercentage = Math.floor(
        (genderGraphData.female * 100) / (genderGraphData.male + genderGraphData.female),
      );
      genderChartData = [genderGraphData.female, genderGraphData.male];
    }
    const genderChart = {
      chartData: {
        datasets: [
          {
            data: genderChartData,
            backgroundColor:
              biggestGender === 'male'
                ? [isPitch ? '#DEDEDE' : '#14B3FC', '#868E8F']
                : ['#FFE710', '#868E8F'],
            borderWidth: 0,
            cutout: '90%',
          },
        ],
      },
      chartPercentage: genderPercentage,
      chartTitle: biggestGender,
    };
    return genderChart;
  }

  setAudienceStats(statsObject, socialMetrics) {
    if (
      statsObject &&
      (statsObject.hasOwnProperty('instagram') || statsObject.hasOwnProperty('facebook'))
    ) {
      socialMetrics = {
        audienceGenders:
          statsObject.instagram && statsObject.instagram.audienceGenders
            ? statsObject.instagram.audienceGenders
            : statsObject.facebook
            ? statsObject.facebook.audienceGenders
            : null,
        audiencePerAge:
          statsObject.instagram && statsObject.instagram.audienceGendersPerAge
            ? statsObject.instagram.audienceGendersPerAge
            : statsObject.facebook
            ? statsObject.facebook.audiencePerAge
            : null,
      };
    }
    return socialMetrics;
  }

  getProfiles() {
    return [
      { label: 'Relevance', value: '', show: true, configuration: {} },
      {
        label: 'Popularity',
        value: 'popularity',
        show: true,
        configuration: { popularityWeight: 4, curatorsWeight: 0.5 },
      },
      {
        label: 'Recent',
        value: 'recent',
        show: true,
        configuration: {
          decay: 0.1,
          recentWeight: 5,
          curatorsWeight: 1.2,
          randomWeight: 1,
          performanceWeight: 0.3,
          recordingWeight: 1,
          qualityWeight: 0.7,
        },
      },
      {
        label: 'Undiscovered',
        value: 'undiscovered',
        show: this.commonService.isAllowed(['ListTiers']),
        configuration: {
          curatorsModifier: 'reciprocal',
          qualityWeight: 2,
          performanceWeight: 1,
          recordingWeight: 4,
        },
      },
      {
        label: 'Hidden Gems',
        value: 'hiddenGems',
        show: true,
        configuration: {
          curatorsWeight: 0.3,
          recentWeight: 0.3,
          randomWeight: 1,
          performanceWeight: 3,
          recordingWeight: 3,
          qualityWeight: 3,
          maxBoost: 11,
        },
      },
      {
        label: 'Advanced',
        value: 'advanced',
        show: true,
        configuration: {},
      },
    ];
  }
}
